.warning {
	padding: 20px 20px;
	max-width: 378px;
	border: 1px solid #3e3e3e;
	border-radius: 8px;
	color: #e7a542;
	display: flex;
	justify-content: center;
	align-items: start;
}
.warning__copy {
	font-size: 18px;
	margin-left: 20px;
    text-align: left;
}