html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: black;
  overflow: hidden;
}

.Frame {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.Frame--rotate-right {
  /* rotate the frame from bottom left corner,
  then translate to the corner and resize */
  transform: translateY(-100vw) rotate(90deg);
  transform-origin: bottom left;
  width: 100vh;
  height: 100vw;
}
.Frame--rotate-left {
  /* rotate the frame from bottom left corner,
  then translate to the corner and resize */
  transform: translateY(100vh) rotate(270deg);
  transform-origin: top left;
  width: 100vh;
  height: 100vw;
}
.Frame--rotate-180 {
  /* rotate the frame from center */
  transform: rotate(180deg);
}
.Frame--hide-cursor {
  /* hide the cursor */
  cursor: none;
}

.Frame-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Frame-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Frame-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Frame-link {
  color: #61dafb;
}

.disconnected-indicator {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #ff0000;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.disconnected-indicator--show {
  opacity: 1;
}

@keyframes pulse {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.pulse {
  pointer-events: none;
  animation: pulse infinite 1s ease-in-out alternate;
}
