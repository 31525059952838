.trial-mode-box {
    width: 474px;
    height: 81px;
    border-radius: 16px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Source Sans Pro;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
    z-index: 9999;
}
.trial-mode-box div p:first-child{
    font-size: 18px;
    font-weight: 600;
} 
.trial-mode-box div p{
    line-height: 0.69;
}