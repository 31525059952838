.pairing-screen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	color: white;
	opacity: 0;
	transition: opacity 1s ease-in-out;
}
.pairing-screen--show {
	opacity: 1;
}
.pairing-screen_content {
	flex: 8.5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.pairing-screen_qr {
	margin: 20px 0 10px;
	padding: 8px 8px 5px;
	background: #ffffff;
}
.pairing-screen_title {
	font-weight: bold;
	font-size: 32px;
}
.pairing-screen_instructions {
    color: #aaaaaa;
    margin: 0;
    font-size: 20px;
}
.pairing-screen_code {
	margin: 80px 0;
	padding: 10px 60px;
	background-color: #2b2b2b;
	border-radius: 8px;
	letter-spacing: 4px;
	font-size: 34px;
}
.pairing-screen_zeroframe {
	flex: 1.5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 2px;
	opacity: 0.5;

}
