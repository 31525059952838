@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Mono&display=swap);
body {
  margin: 0;
}

* {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Source Sans Pro", sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

.mono {
    font-family: 'PT Mono', monospace;
}

.warning {
	padding: 20px 20px;
	max-width: 378px;
	border: 1px solid #3e3e3e;
	border-radius: 8px;
	color: #e7a542;
	display: flex;
	justify-content: center;
	align-items: start;
}
.warning__copy {
	font-size: 18px;
	margin-left: 20px;
    text-align: left;
}
.status-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: white;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.status-screen--show {
  opacity: 1;
}
.status-screen_content {
  flex: 8.5 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.status-screen_title {
  font-weight: bold;
}
.status-screen_zeroframe {
  flex: 1.5 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: revert;
  font-size: 20px;
  opacity: 0.5;
}

.Artwork {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.Artwork--hidden {
  visibility: hidden;
}

.artwork-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
}
.artwork-loading--show {
  opacity: 1;
}

.Artwork .image,
.Artwork .video,
.Artwork .website {
  width: 100%;
  height: 100%;
}

.Artwork .website {
  border: 0;
}

.artwork-wrap {
  height: 100%;
  width: 100%;
  opacity: 0;
}

.artwork-wrap--crossfade-enter-active,
.artwork-wrap--crossfade-enter-done,
.artwork-wrap--crossfade-exit,
.artwork-wrap--fadeoutin-enter-active,
.artwork-wrap--fadeoutin-enter-done,
.artwork-wrap--fadeoutin-exit {
  opacity: 1;
  transition: opacity 0.75s ease-in-out;
}
.artwork-wrap--fadeoutin-enter-active {
  transition-delay: 0.75s;
}
.artwork-wrap--crossfade-exit-active,
.artwork-wrap--crossfade-exit-done,
.artwork-wrap--fadeoutin-exit-active,
.artwork-wrap--fadeoutin-exit-done {
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
}

.plaque-container {
	cursor: pointer;
	position: fixed;
	bottom: 20px;
	left: 20px;
	max-width: calc(100% - 50px);
	padding: 10px 0 10px 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	-webkit-transform-origin: bottom left;
	        transform-origin: bottom left;
}

.plaque-container--sm {
	-webkit-transform: scale(0.5);
	        transform: scale(0.5);
}

.plaque-container--md {
	-webkit-transform: scale(0.75);
	        transform: scale(0.75);
}

.plaque-container--lg {
	-webkit-transform: scale(1);
	        transform: scale(1);
}

.info-container {
	color: #fff;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 28px 0 18px;
	overflow: hidden;
}

.qc-container {
	background-color: #000;
	margin-right: 10px;
}

.qc-container canvas,
.qc-container svg {
	display: block;
}

.art-author {
	display: block;
	font-size: 50px;
	font-weight: 700;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.art-title {
	font-size: 50px;
	font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.pairing-screen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	color: white;
	opacity: 0;
	transition: opacity 1s ease-in-out;
}
.pairing-screen--show {
	opacity: 1;
}
.pairing-screen_content {
	flex: 8.5 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.pairing-screen_qr {
	margin: 20px 0 10px;
	padding: 8px 8px 5px;
	background: #ffffff;
}
.pairing-screen_title {
	font-weight: bold;
	font-size: 32px;
}
.pairing-screen_instructions {
    color: #aaaaaa;
    margin: 0;
    font-size: 20px;
}
.pairing-screen_code {
	margin: 80px 0;
	padding: 10px 60px;
	background-color: #2b2b2b;
	border-radius: 8px;
	letter-spacing: 4px;
	font-size: 34px;
}
.pairing-screen_zeroframe {
	flex: 1.5 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 2px;
	opacity: 0.5;

}

.controls {
  position: absolute;
  width: 100%;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  top: -100px;
  height: 0;
  /* background-color: white; */
  /* padding: 20px; */
}
.controls--show {
  top: 0;
  height: 100px;
  opacity: 1;
}
.controls_content {
  display: flex;
  /* align-items: flex-start; */
  align-items: center;
  justify-content: space-between;
  padding: 25px;
}

.controls_item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 50px;
  border: none;
  color: white;
  background-color: black;
  font-weight: bold;
  font-size: 14px;
}

.controls_button {
  cursor: pointer;
}

.controls_button:hover {
  background-color: #111;
}

.controls_pill {
  padding: 12px 20px;
  border-radius: 50px;
  font-size: 20px;
}

.controls_connected_indicator {
  border-radius: 10px;
  width: 15px;
  height: 15px;
  background-color: #aaa;
  display: inline-block;
  margin-right: 10px;
}
.controls_connected_indicator--connected {
  background-color: #13C93B;
}
.controls_connected_indicator--disconnected {
  background-color: #ff0000;
}
html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: black;
  overflow: hidden;
}

.Frame {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.Frame--rotate-right {
  /* rotate the frame from bottom left corner,
  then translate to the corner and resize */
  -webkit-transform: translateY(-100vw) rotate(90deg);
          transform: translateY(-100vw) rotate(90deg);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  width: 100vh;
  height: 100vw;
}
.Frame--rotate-left {
  /* rotate the frame from bottom left corner,
  then translate to the corner and resize */
  -webkit-transform: translateY(100vh) rotate(270deg);
          transform: translateY(100vh) rotate(270deg);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  width: 100vh;
  height: 100vw;
}
.Frame--rotate-180 {
  /* rotate the frame from center */
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.Frame--hide-cursor {
  /* hide the cursor */
  cursor: none;
}

.Frame-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Frame-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.Frame-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Frame-link {
  color: #61dafb;
}

.disconnected-indicator {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #ff0000;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.disconnected-indicator--show {
  opacity: 1;
}

@-webkit-keyframes pulse {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.pulse {
  pointer-events: none;
  -webkit-animation: pulse infinite 1s ease-in-out alternate;
          animation: pulse infinite 1s ease-in-out alternate;
}

.trial-mode-box {
    width: 474px;
    height: 81px;
    border-radius: 16px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-family: Source Sans Pro;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
    z-index: 9999;
}
.trial-mode-box div p:first-child{
    font-size: 18px;
    font-weight: 600;
} 
.trial-mode-box div p{
    line-height: 0.69;
}
