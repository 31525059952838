@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');

body {
  margin: 0;
}

* {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Source Sans Pro", sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

.mono {
    font-family: 'PT Mono', monospace;
}
