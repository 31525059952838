.status-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: white;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.status-screen--show {
  opacity: 1;
}
.status-screen_content {
  flex: 8.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.status-screen_title {
  font-weight: bold;
}
.status-screen_zeroframe {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: revert;
  font-size: 20px;
  opacity: 0.5;
}
