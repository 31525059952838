.plaque-container {
	cursor: pointer;
	position: fixed;
	bottom: 20px;
	left: 20px;
	max-width: calc(100% - 50px);
	padding: 10px 0 10px 10px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	transform-origin: bottom left;
}

.plaque-container--sm {
	transform: scale(0.5);
}

.plaque-container--md {
	transform: scale(0.75);
}

.plaque-container--lg {
	transform: scale(1);
}

.info-container {
	color: #fff;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 28px 0 18px;
	overflow: hidden;
}

.qc-container {
	background-color: #000;
	margin-right: 10px;
}

.qc-container canvas,
.qc-container svg {
	display: block;
}

.art-author {
	display: block;
	font-size: 50px;
	font-weight: 700;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.art-title {
	font-size: 50px;
	font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
