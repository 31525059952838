.controls {
  position: absolute;
  width: 100%;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  top: -100px;
  height: 0;
  /* background-color: white; */
  /* padding: 20px; */
}
.controls--show {
  top: 0;
  height: 100px;
  opacity: 1;
}
.controls_content {
  display: flex;
  /* align-items: flex-start; */
  align-items: center;
  justify-content: space-between;
  padding: 25px;
}

.controls_item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 50px;
  border: none;
  color: white;
  background-color: black;
  font-weight: bold;
  font-size: 14px;
}

.controls_button {
  cursor: pointer;
}

.controls_button:hover {
  background-color: #111;
}

.controls_pill {
  padding: 12px 20px;
  border-radius: 50px;
  font-size: 20px;
}

.controls_connected_indicator {
  border-radius: 10px;
  width: 15px;
  height: 15px;
  background-color: #aaa;
  display: inline-block;
  margin-right: 10px;
}
.controls_connected_indicator--connected {
  background-color: #13C93B;
}
.controls_connected_indicator--disconnected {
  background-color: #ff0000;
}