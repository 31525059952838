.Artwork {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.Artwork--hidden {
  visibility: hidden;
}

.artwork-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
}
.artwork-loading--show {
  opacity: 1;
}

.Artwork .image,
.Artwork .video,
.Artwork .website {
  width: 100%;
  height: 100%;
}

.Artwork .website {
  border: 0;
}

.artwork-wrap {
  height: 100%;
  width: 100%;
  opacity: 0;
}

.artwork-wrap--crossfade-enter-active,
.artwork-wrap--crossfade-enter-done,
.artwork-wrap--crossfade-exit,
.artwork-wrap--fadeoutin-enter-active,
.artwork-wrap--fadeoutin-enter-done,
.artwork-wrap--fadeoutin-exit {
  opacity: 1;
  transition: opacity 0.75s ease-in-out;
}
.artwork-wrap--fadeoutin-enter-active {
  transition-delay: 0.75s;
}
.artwork-wrap--crossfade-exit-active,
.artwork-wrap--crossfade-exit-done,
.artwork-wrap--fadeoutin-exit-active,
.artwork-wrap--fadeoutin-exit-done {
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
}
